<template>
  <v-app-bar
    id="navbar"
    app
    color="primary"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    flat
    class="px-md-4"
  >
    <default-navigation
      class="d-flex align-center"
      color
      dark
    />

    <v-spacer />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () =>
        import(
          /* webpackChunkName: "default-account" */
          './widgets/Account'
        ),
      DefaultNavigation: () =>
        import(
          /* webpackChunkName: "default-account" */
          './widgets/Navigation'
        ),
    },

    computed: {
      ...sync('app', ['drawer', 'mini']),
      ...get('route', ['meta', 'name']),
    },
  }
</script>
<style scoped>
#navbar{
  position:relative;
  flex:unset;

}
</style>
