<template>
  <v-app-bar
    id="navbar"
    app
    color="menuListados"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    flat
    height="75"
    elevation="4"
  >
    <v-img
      class="mr-6"
      contain
      width="55px"
      src="@/assets/logo.svg"
    />

    <default-navigation
      class="d-flex align-center"
      color="greyTxt"
    />

    <v-spacer />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () =>
        import(
          /* webpackChunkName: "default-account" */
          '@/layouts/default/widgets/Account'
        ),
      DefaultNavigation: () =>
        import(
          /* webpackChunkName: "default-account" */
          '@/layouts/default/widgets/Navigation'
        ),
    },

    computed: {
      ...sync('app', ['drawer', 'mini']),
      ...get('route', ['meta', 'name']),
    },
  }
</script>
<style scoped>
#navbar{
  position:relative;
  flex:unset;

}
</style>
